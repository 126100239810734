body{
    background-color: #f3f2f2 !important;
}
h1{
    color: #9fa1a4;
}
.info{
    padding-top: 5rem;
}

.info p{
    padding: 0;
    margin: 0;
}

#icon{
    background-color: #ff1b83;
    border-radius: 100%;
    color:white;
    text-align: center;
    float:left;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
}

.cambioContraseña{
    overflow: hidden;
}

.form-control{
    background-color:#f3f2f2;
}
.form-control:focus{
    background-color:#f3f2f2;
}

.color-black{
    color:black !important;
}

.cambio-contraseña-font-size{
    font-size: 0.8rem;
}
/* 
#formularioNuevaContraseña{
    padding-top: 1rem;
}

#formularioNuevaContraseña input{
    padding-right: 0px;
} */
.cambio-contraseña-cursor:hover{
    cursor: pointer;
}
.cambio-contraseña-danger-color{
    color: #ff1580;
}

#cambioContraseña{
    display: none;
}

#cambioContraseñaExitoso{
    display: none;
}

/* #cambioContraseñaExitoso img{
    width: 13%;
    height: 13%;
} */

#nuevaContraseña{
    cursor: hand;
}



@media (min-width: 900px){
.container-girl .img-girl{
    min-height: 600px;
}

.imagenGirl{
    position: absolute;
    bottom: 0;
    margin: auto;
    right: 0;
    max-width: 430px;
    width: 430px;
}

}

input::-ms-clear, input::-ms-reveal {
    display: none;
}

.buttonMore{
    background-color: #ff1487;
    padding: 1px 7px;
    border-radius: 15px;
    margin: 0 5px;
    color: white;
}

.btnSaveChanges:hover, .btnSaveChanges:focus, .btnSaveChanges:active{
    background-color: #ff1580!important;
    border: none;
    border-color: transparent;
    box-shadow: none;
}

.mt-13{
    margin-top: 8rem;
}


.formWatermarks{
    min-height: 500px;
}

.fileSpace{
    border-radius: 10px;
    background-color: #f3f2f2;
    font-size: 12px!important;
}

.marcoFormulario{
    background-color: #fff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: -2px 2px 2px 2px;
}

.buttonMoreWatermarkMore{
    background-color: #ff1487;
    color: #fff;
    font-weight: 700;
    /* width: 50px; */
    border-radius: 50%;
    width: 24px;
    max-width: 24px;
    text-align: center;
    position: absolute;
    right: 30px;
    padding: 0;
}

.buttonContinue{
    background-color: #ff1487;
    color: #fff;
}


.loaderProcess{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: #f1f2f2;
    top: 0;
}

.loaderProcess > div{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: inline-block;
    height: 210px;
}

.min-size{
    min-height: 600px;
}