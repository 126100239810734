.terms__app{
    color: black;
}

.terms__h3{
    text-align: justify;

}

.terms__p{
    text-align: justify;
    font-size: 14px;
}

.terms__li{
    text-align: justify;
    font-size: 14px;
}
.terms__ul{
    text-align: justify;
    font-size: 14px;
}

.terms__color-header{
    background-color: #0d114f;
}

.terms__container{
    padding: 2.4rem 0;
    margin: 80px auto auto auto;
}