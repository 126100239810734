.privacy__app{
    color: black;
}

.privacy__h3{
    text-align: justify;

}

.privacy__p{
    text-align: justify;
    font-size: 14px;
}
.privacy__li{
    text-align: justify;
    font-size: 14px;
}
.privacy__ul{
    text-align: justify;
    font-size: 14px;
}

.privacy__container{
    padding: 2.4rem 0;
    margin: 80px auto auto auto;
}