.header-statistics{
    background-color: #333333;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}


.color-text-white{
    color: white;
}

  /* burger-statistic */
  .burger-statistic {
    width: 28px;
    height: 32px;
    cursor: pointer;
    position: relative;
    z-index: 99;
    float: right;
  }
  
  .burger-statistic:before, .burger-statistic span, .burger-statistic:after {
    width: 100%;
    height: 2px;
    display: block;
    background: white;
    border-radius: 2px;
    position: absolute;
    opacity: 1;
  }
  
  .burger-statistic:before, .burger-statistic:after {
    transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
    -webkit-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
    content: "";
  }
  
  .burger-statistic:before {
    top: 4px;
  }
  
  .burger-statistic span {
    top: 15px;
  }
  
  .burger-statistic:after {
    top: 26px;
  }
  
  /* Hover */
  .burger-statistic:hover:before {
    top: 7px;
  }
  
  .burger-statistic:hover:after {
    top: 23px;
  }
  
  /* Click */
  .burger-statistic.active span {
    opacity: 0;
  }
  
  .burger-statistic.active:before, .burger-statistic.active:after {
    top: 40%;
  }
  
  .burger-statistic.active:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=5);
    /*for IE*/
  }
  
  .burger-statistic.active:after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=-5);
    /*for IE*/
  }
  
  .burger-statistic:focus {
    outline: none;
  }

  .btn-filters{
    background-color: transparent !important;
    color: white;
    border-color: white;
    border: 1px solid white;
  }

  .btn-search{
    background-color: #FF1B83;
    color: white;
    border-color: white;
    /* border: 1px solid white; */
  }

  .btn-filters:hover{
    background-color: transparent !important;
    color: white;
    border: 1px solid white;

  }
  .btn-download-list{
    background-color: transparent !important;
    color: white;
  }

  .btn-download-list:hover{
    background-color: transparent !important;
    color: white;

  }

  .button-menu{
    background-color: transparent;
    border: 0;
  }

  .icon-white{
    color:white;
  }
  .header-menu-statistics{
    color: black;
  }
  .my-menuitem{
    background-color: #333333;
  }
  .selector-country{
    background-color: transparent;
    border-color: white;
    color: white;
    border: solid 1px;
    border-radius: 20px;
    /* max-width: 150px; */
    font-size: 1rem !important;
    height: calc(1.5em + .75rem + 2px) !important;
  }
  .color-red{
    color: red;
  }

  .boton-descargar-lista{
    bottom: 0;
    right: 0;
  }

  @media (min-width: 992px){
    .h3{
        font-size: 1.75rem;
    }
  }

  @media(min-width: 768px){
    .boton-descargar-lista{
      position: absolute;

    }

  }
  @media (min-width: 768px) and (max-width: 991.98px){
    .h3{
        font-size: 1.25rem;
    }
  }
  @media (max-width: 767.98px){
    .h3{
        font-size: 1rem;
    }
  }

.text-align-last-center{
  text-align-last: center;
}