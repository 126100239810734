.btnColor:hover, .btnColor:focus, .btnColor:active{
    background-color: #FF1B83!important;
    border: none;
    border-color: transparent;
    box-shadow: none;
}

.watermark-title{
    font-size: 1.5rem;
}
@media (min-width: 700px) {
    .create-first-watermark {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 2px 2px 0.5px #c4c4c4;
    }
    .min-size{
        height: 600px;
    }
}

.btn-filter-watermark{
    color: #fff;
}

@media (min-width: 250px){
    .min-size{
        height: 500px;
    }
}

.create-first-watermark img{
    max-width: 50px;
    width: 45px;
}
.color-black{
    color:black !important;
}

.buttonMoreWatermark{
    background-color: #FF1B83;
    padding: 0.3rem 0.9rem;
    border-radius: 100%;
    margin: 0;
    color: white;
    font-size: 2rem;
}

.pageBound-active{
    background-color: #FF1B83;
    height: 25px;
    width: 25px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.125rem;
    margin-left: 0.125rem;
    box-shadow: 0px 1px 1px 0.5px #c4c4c4;
    border-radius: 15%;

}
.pageBound-active a{
    color: white;
}
.pageBound-inactive{
    background-color: white;
    height: 25px;
    width: 25px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.125rem;
    margin-left: 0.125rem;
    border-radius: 15%;
    box-shadow: 0px 1px 1px 0.5px #c4c4c4;


}

a, a:hover{
    text-decoration: none;
}

.cursor-hand{
    cursor: pointer;

}
