.watermark{
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0.5px #c4c4c4;
}

@media (min-width: 850px) {
    .large-height{
        
        min-height: 600px;
    }
}

@media (min-width:770px){
    .watermark{
        min-height: 110px;
    }
}

.small-font-size{
    font-size: 0.75rem;
}

.icon-image{
    min-width: 40px;
    max-height: 40px;
    max-width: 50px;
    max-height: 50px;
}
.statistic-hover{
    cursor: pointer;
}

.watermark-font-size{
    font-size: 0.85rem;
}
.size-2-rem{
    width: 2rem;
}

.size-11-rem{
    width: 11rem;
}