#menuLogin .site-navbar {
    margin-bottom: 0px;
    z-index: 1999;
    top: 0;
    position: relative;
    width: 100%;
    color: black;
    background-color: white;
}
  
  .site-navbar .site-logo {
    position: relative;
    font-size: 24px;
  }
  
  .site-navbar .site-logo a {
    color: #fff;
  }
  
  .site-navbar .site-logo a:hover {
    text-decoration: none;
  }
  
  .site-navbar .site-navigation .site-menu {
    margin-bottom: 0;
  }
  
  .site-navbar .site-navigation .site-menu a {
    text-decoration: none !important;
    display: inline-block;
  }
  
  .site-navbar .site-navigation .site-menu > li {
    display: inline-block;
  }
  
  .site-navbar .site-navigation .site-menu > li > a {
    padding: 30px 20px;
    color: #000000;
    display: inline-block;
    text-decoration: none !important;
  }
  
  .site-navbar .site-navigation .site-menu > li > a:hover {
    color: #0d114f;
  }
  
  .site-navbar .site-navigation .site-menu > li.active > a {
    color: #fff !important;
  }
  
  .site-navbar .site-navigation .site-menu .has-children {
    position: relative;
  }
  
  .site-navbar .site-navigation .site-menu .has-children > a {
    position: relative;
    padding-right: 20px;
  }
  
  .site-navbar .site-navigation .site-menu .has-children > a:before {
    position: absolute;
    content: "\ea99";
    font-size: 16px;
    top: 52%;
    right: 0;
    transform: translateY(-50%);
    font-family: 'icofont';
  }
  
  .site-navbar .site-navigation .site-menu .has-children .dropdown {
    visibility: hidden;
    opacity: 0;
    top: 100%;
    position: absolute;
    text-align: left;
    box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
    padding: 0px 0;
    margin-top: 15px;
    margin-left: 0px;
    background: #fff;
    transition: 0.2s 0s;
  }
  
  .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
    position: absolute;
  }
  
  .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
    bottom: 100%;
    left: 20%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  
  .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px;
  }
  
  .site-navbar .site-navigation .site-menu .has-children .dropdown a {
    text-transform: none;
    letter-spacing: normal;
    transition: 0s all;
    color: #000000;
  }
  
  .site-navbar .site-navigation .site-menu .has-children .dropdown .active {
    color: #0d114f !important;
  }
  
  .site-navbar .site-navigation .site-menu .has-children .dropdown > li {
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 210px;
  }
  
  .site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
    padding: 9px 20px;
    display: block;
  }
  
  .site-navbar .site-navigation .site-menu .has-children .dropdown > li > a:hover {
    background: #f2f4f6;
    color: #000000;
  }
  
  .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > a:before {
    content: "\ea75";
    right: 20px;
  }
  
  .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > .dropdown, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > ul {
    left: 100%;
    top: 0;
  }
  
  .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:active > a, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:focus > a {
    background: #f2f4f6;
  }
  
  .site-navbar .site-navigation .site-menu .has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children:focus > a, .site-navbar .site-navigation .site-menu .has-children:active > a {
    color: #0d114f;
  }
  
  .site-navbar .site-navigation .site-menu .has-children:hover, .site-navbar .site-navigation .site-menu .has-children:focus, .site-navbar .site-navigation .site-menu .has-children:active {
    cursor: pointer;
  }
  
  .site-navbar .site-navigation .site-menu .has-children:hover > .dropdown, .site-navbar .site-navigation .site-menu .has-children:focus > .dropdown, .site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
    transition-delay: 0s;
    margin-top: -10px;
    visibility: visible;
    opacity: 1;
  }
  
  .site-mobile-menu {
    width: 300px;
    position: fixed;
    right: 0;
    z-index: 2000;
    padding-top: 20px;
    background: #fff;
    height: calc(100vh);
    transform: translateX(110%);
    box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
    transition: .3s all ease-in-out;
  }
  
  .offcanvas-menu .site-mobile-menu {
    transform: translateX(0%);
  }
  
  .site-mobile-menu .site-mobile-menu-header {
    width: 100%;
    float: left;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
    float: right;
    margin-top: 8px;
  }
  
  .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
    font-size: 30px;
    display: inline-block;
    padding-left: 10px;
    padding-right: 0px;
    line-height: 1;
    cursor: pointer;
    transition: .3s all ease;
  }
  
  .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
    float: left;
    margin-top: 10px;
    margin-left: 0px;
  }
  
  .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
    display: inline-block;
    text-transform: uppercase;
  }
  
  .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
    max-width: 70px;
  }
  
  .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
    text-decoration: none;
  }
  
  .site-mobile-menu .site-mobile-menu-body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding: 0 20px 20px 20px;
    height: calc(100vh - 52px);
    padding-bottom: 150px;
  }
  
  .site-mobile-menu .site-nav-wrap {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
  }
  
  .site-mobile-menu .site-nav-wrap a {
    padding: 10px 20px;
    display: block;
    position: relative;
  }
  
  .site-mobile-menu .site-nav-wrap a:hover {
    color: #0d114f;
  }
  
  .site-mobile-menu .site-nav-wrap li {
    position: relative;
    display: block;
  }
  
  .site-mobile-menu .site-nav-wrap li.active > a {
    color: #2d71a1;
  }
  
  .site-mobile-menu .site-nav-wrap .arrow-collapse {
    position: absolute;
    right: 0px;
    top: 10px;
    z-index: 20;
    width: 36px;
    height: 36px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .site-mobile-menu .site-nav-wrap .arrow-collapse:before {
    font-size: 12px;
    z-index: 20;
    font-family: "icofont";
    content: "\ea99";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-180deg);
    transition: .3s all ease;
  }
  
  .site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
    transform: translate(-50%, -50%);
  }
  
  .site-mobile-menu .site-nav-wrap > li {
    display: block;
    position: relative;
    float: left;
    width: 100%;
  }
  
  .site-mobile-menu .site-nav-wrap > li > a {
    padding-left: 20px;
    font-size: 20px;
  }
  
  .site-mobile-menu .site-nav-wrap > li > ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .site-mobile-menu .site-nav-wrap > li > ul > li {
    display: block;
  }
  
  .site-mobile-menu .site-nav-wrap > li > ul > li > a {
    padding-left: 40px;
    font-size: 16px;
  }
  
  .site-mobile-menu .site-nav-wrap > li > ul > li > ul {
    padding: 0;
    margin: 0;
  }
  
  .site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
    display: block;
  }
  
  .site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
    font-size: 16px;
    padding-left: 60px;
  }
  
  /* Burger */
  .burger {
    width: 28px;
    height: 32px;
    cursor: pointer;
    position: relative;
    z-index: 99;
    float: right;
  }
  
  .burger:before, .burger span, .burger:after {
    width: 100%;
    height: 2px;
    display: block;
    border-radius: 2px;
    position: absolute;
    opacity: 1;
  }
  .burger-background-pink:before, .burger-background-pink span, .burger-background-pink:after {
    background-color: #FF1B83;
  }
  
  .burger:before, .burger:after {
    transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
    -webkit-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
    content: "";
  }
  
  .burger:before {
    top: 4px;
  }
  
  .burger span {
    top: 15px;
  }
  
  .burger:after {
    top: 26px;
  }
  
  /* Hover */
  .burger:hover:before {
    top: 7px;
  }
  
  .burger:hover:after {
    top: 23px;
  }
  
  /* Click */
  .burger.active span {
    opacity: 0;
  }
  
  .burger.active:before, .burger.active:after {
    top: 40%;
  }
  
  .burger.active:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=5);
    /*for IE*/
  }
  
  .burger.active:after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=-5);
    /*for IE*/
  }
  
  .burger:focus {
    outline: none;
  }

  .highlighted{
    color: #ff1580!important;
  }
  .sticky-header{

    position: sticky;
    top: 0;
  }
  .margin-top-80-px{
    margin-top: 80px;
  }